.google-btn{
    width: 300px;
    height: 50px;
    font-size: 20px;
    font-weight: 800;
    border-radius: 5px;
    border: 2px solid black;
background-color: rgb(201, 201, 240);
    text-shadow: 4px 4px 6px red;
    box-shadow: 4px 4px 6px rgb(4, 0, 255);
    margin-top: 30px;
    margin-left: 42vw;
}