.a-container{
    width: 40vw;
    height: 65vh;
    background-color: rgb(223, 230, 244);
    margin-left: 30vw;
    margin-top: 10vh;
    border-radius: 3px;
    border:  2px solid black;
}
.new-expense{
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
}
.new-expense input{
    width: 20vw;
    height: 3vh;
}
.new-expense label{
    margin-top: 5px;
    font-weight: 500;
}
.add-buttons{
    display: flex;
    justify-content: space-between;
    width: 20vw;
    
}
.add-buttons input{
    width: 9vw;
    height: 4vh;
    margin-top: 20px;
    margin-left: 5px;
    cursor: pointer;
   
}
.submit-btn{
    background-color: rgb(142, 207, 247);
    font-size: 15px;
    font-weight: 600;
    border: 2px solid black;
}
.cancel-button{
    background-color: rgb(241, 136, 84);
    font-size: 15px;
    font-weight: 600;
    border: 2px solid black;
}
.submit-btn:hover{
    background: rgb(63, 174, 243);
}
.cancel-button:hover{
    background:rgb(241, 228, 84) ;
}
.new-expense h1{
    width: 20vw;
    text-align: center;
    margin-left: 10px;
    color: rgb(27, 53, 91);
}
.select{
    width: 20.5vw;
    height: 4vh;
}
.date{
    width: 20.5vw;
    height: 4.5vh;
}